<template>
    <div class='row p-0 m-0'>
        <div class='col-10'>

            <h3 class='m-0 p-0'>
                <a :href='generateReport(item)'>
                    <font-awesome-icon icon='eye' class='my_icon' />
                    &nbsp;
                    {{ item.name }}
                </a>
            </h3>

        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/domains/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string}  id
     * @property {string}  name
     * @property {string}  is_favorite
     * @property {string}  created_at
     * @property {string}  category_id
     */
    const props = defineProps({
        item: Object,
        color: String,
        is_archive: Boolean
    });

    const generateReport = (record) => {
        const { report, id, name } = record;
        const { query, limit, sort, ascending, filters, section, sub_section } = report;
        const params = [
            query ? `search_query=${query}` : '',
            limit ? `limit=${limit}` : '',
            sort ? `sort=${sort}` : '',
            ascending ? `ascending=${ascending}` : '',
            filters ? `filters=${JSON.stringify(filters)}` : ''
        ];
        const params_str = params.filter(param => param).join('&');
        const sub_section_str = sub_section ? `${section}/${id}/${sub_section}` : '';
        return `/${section}/${sub_section_str}?${params_str}&id=${id}&name=${name}`;
    };
</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .my_icon {
        top: 0 !important;
        position: relative !important;
    }
</style>
