<template>
    <div class='dropdown nav-item notification-dropdown btn-group' v-if='loggedIn'>
        <a href='#' id='ddlnotify'
           data-bs-toggle='dropdown'
           aria-expanded='true'
           class='btn dropdown-toggle btn-icon-only nav-link' v-if='notifications && notifications.length > 0'>
            <font-awesome-icon icon='bell' />
            <span class='badge badge-success' v-if='has_notifications'></span>
        </a>
        <a href='#' id='ddlnotify' @click='get_notifications'
           class='btn dropdown-toggle btn-icon-only nav-link' v-else>
            <font-awesome-icon icon='bell' />
        </a>

        <ul class='dropdown-menu dropdown-menu-right' aria-labelledby='ddlnotify' data-bs-popper='none'
            ref='notification_dropdown_ref' v-show='notifications && notifications.length > 0'>
            <li role='presentation'>
                <div class=''>
                    <a href='#' class='dropdown-item float-end' @click='clearAll()'>
                        <div class='float-end'>
                            Clear All
                        </div>
                    </a>
                    <hr role='separator' aria-orientation='horizontal' size='0' class='dropdown-divider'>
                </div>
            </li>
            <transition-group name='list' tag='li'>
                <li role='presentation' v-for='(notification, index) in notifications' :key='notification.id'>
                    <a href='#' class='dropdown-item'>
                        <div class='media'
                             :class='{"item-danger": !notification.is_success, "item-success": notification.is_success}'>

                            <!-- Media Aside -->
                            <div class='media-aside align-self-start'>
                                <font-awesome-icon :icon='notification.icon' />
                            </div>

                            <!-- Media Body -->
                            <div class='media-body'>
                                <div class='data-info'>
                                    <h6>{{ notification.title }}</h6>
                                    <p>{{ moment(notification.created_at).fromNow() }}</p>
                                </div>
                                <div class='icon-status' @click.stop='clear(notification)'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                                         fill='none'
                                         stroke='currentColor' stroke-width='2' stroke-linecap='round'
                                         stroke-linejoin='round'
                                         class='feather feather-x'>
                                        <line x1='18' y1='6' x2='6' y2='18'></line>
                                        <line x1='6' y1='6' x2='18' y2='18'></line>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </a>
                    <hr role='separator' aria-orientation='horizontal' size='0' class='dropdown-divider'
                        v-if='index < notifications.length - 1'>
                </li>
            </transition-group>
        </ul>
    </div>
</template>


<script setup>

    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import UserService from '@/services/user.service';
    import moment from 'moment/moment';


    const clearAll = async () => {
        const response = await UserService.updateData('/notifications/clear_notifications', { is_read: true });
        if (response.data.success) {
            notifications.value = [];
            has_notifications.value = false;
            // Hide the dropdown
            if (notification_dropdown_ref && notification_dropdown_ref.value) {
                // Check if it has class to delete
                if (notification_dropdown_ref.value.classList.contains('show')) {
                    notification_dropdown_ref.value.classList.remove('show');
                }
            }

        }
    };

    const clear = async (notification) => {
        let data = notification;
        data.is_read = true;
        const response = await UserService.updateData('/notifications/' + notification.id, data);
        if (response.data.success) {
            notifications.value = notifications.value.filter(item => item.id !== notification.id);
            has_notifications.value = notifications.value.length > 0;
            if (!has_notifications.value) {
                // Hide the dropdown
                if (notification_dropdown_ref && notification_dropdown_ref.value) {
                    notification_dropdown_ref.value.classList.remove('show');
                }
            }
        }
    };

    const get_notifications = async () => {
        const response = await UserService.getData('/notifications/');
        if (response && response.data) {
            notifications.value = response.data.data;
            has_notifications.value = notifications.value ? notifications.value.length > 0 : false;
        }
    };

    const notification_dropdown_ref = ref(null);
    const has_notifications = ref(false);
    const notifications = ref([]);
    const store = useStore();
    const loggedIn = computed(() => store.state.auth.status.loggedIn);
    onMounted(async () => {

        if (loggedIn.value) {
            await get_notifications();
        }

    });

</script>

<style scoped>
    .list-enter-active, .list-leave-active {
        transition: all 0.5s;
    }

    .list-enter, .list-leave-to {
        opacity: 0;
        transform: translateX(-100px);
    }
</style>

