import api from './api';
import TokenService from './token.service';

class AuthService {
    login({ username, password }) {

        const loginFormData = new FormData();
        loginFormData.append('username', username);
        loginFormData.append('password', password);
        return api
            .post('/auth/token', loginFormData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                if (response.data.access_token) {
                    if (!response.data.user.is_mfa_enabled) {
                        TokenService.setToken(response.data);
                    }
                }

                return response.data;
            });
    }

    sso_login({ access_token }) {

        return api
            .post('/auth/sso_token', {
                access_token: access_token
            })
            .then((response) => {
                if (response.data.access_token) {
                    TokenService.setToken(response.data);
                }
                return response.data;
            });
    }

    logout() {
        TokenService.removeToken();
    }

    register({ first_name, last_name, email, plan, password, company_name, has_accepted_terms }) {
        return api.post('/auth/register', {
            first_name,
            last_name,
            email,
            plan,
            company_name,
            password,
            has_accepted_terms
        });
    }

    confirmation({ token }) {
        return api.post('/auth/confirmation', {
            token
        });
    }

    new_user_invitation({ payload }) {
        return api.post('/auth/new_user_invitation', payload);
    }

    invitation_token_check({ token }) {
        return api.post('/auth/invitation_token_check', {
            token
        });
    }

    password_recovery({ email }) {
        return api.post('/auth/password_recovery', {
            email
        });
    }

    password_recovery_token_check({ token }) {
        return api.post('/auth/password_recovery_token_check', {
            token
        });
    }

    password_reset({ payload }) {
        return api.post('/auth/password_reset', payload);
    }


    change_password({ payload }) {
        return api.put('/auth/change_password', payload);
    }

    profile({ payload }) {
        return api.put('/auth/profile', payload);
    }

}

export default new AuthService();
