import axios from "axios";

const instance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_FAST_API,
    headers: {
        "Content-Type": "application/json",
        "access_token": process.env.VUE_APP_ROOT_FAST_API_KEY
    },
});

export default instance;
