import AuthService from '../services/auth.service';
import api from '@/services/api';
import TokenService from '@/services/token.service';
import store from '@/store';
import { showMessage } from '@/composables/utils';
import router from '@/router';

const token = JSON.parse(localStorage.getItem('token'));

const base64urlToUint8Array = (base64url) => {
    const padding = '='.repeat((4 - base64url.length % 4) % 4);
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/') + padding;
    const binary = atob(base64);
    return Uint8Array.from(binary, char => char.charCodeAt(0));
};

const initialState = token
    ? { status: { loggedIn: true, needsVerification: false }, token }
    : { status: { loggedIn: false, needsVerification: false }, token: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, token) {
            return AuthService.login(token).then(
                token => {
                    if (!token.user.is_mfa_enabled && !token.user.is_pass_key_enabled) {
                        commit('loginSuccess', token);
                        commit('updateNeedsVerification', false);
                    } else {
                        commit('updateNeedsVerification', true);
                    }

                    return Promise.resolve(token);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        sso_login({ commit }, access_token) {

            return AuthService.sso_login({ access_token }).then(
                token => {
                    commit('loginSuccess', token);
                    return Promise.resolve(token);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        async updateToken({ commit }, token) {
            commit('updatedTokenSuccess', token);
        },
        async logout({ commit }) {
            const current_token = TokenService.getToken();
            if (current_token !== null && typeof current_token !== 'undefined') {
                setTimeout(() => {
                    if (current_token.access_token !== null && typeof current_token.access_token !== 'undefined') {
                        const headers = {
                            'Authorization': 'Bearer ' + current_token.access_token,
                            'Content-Type': 'application/json'
                        };
                        api.post('/auth/logout', {}, { headers: headers }).catch((error) => {
                            return false;
                        });
                    }
                }, 3000);
            }
            AuthService.logout();
            localStorage.removeItem('token');
            commit('logout');
        },
        async loginWithLink({ commit }, token) {
            commit('loginSuccess', token);
        },
        register({ commit }, token) {
            return AuthService.register(token).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        async confirmCode({ commit }, code) {

            const current_token = store.state.tempToken;
            const headers = {
                'Authorization': 'Bearer ' + current_token.access_token,
                'Content-Type': 'application/json'
            };

            const response = await api.post('/auth/confirmCode/', code, { headers: headers }).catch((error) => {
                return Promise.reject(error);
            });

            if (response.data.success) {
                commit('loginSuccess', response.data.data);
                TokenService.setToken(response.data.data);
                return response;
            } else {
                return response;
            }

        },
        async beginPasskey({ commit }, use_header = true) {
            const current_token = store.state.tempToken;
            let headers = {
                'Authorization': 'Bearer ' + current_token.access_token,
                'Content-Type': 'application/json'
            };

            let begin_url = '/passkey/authenticate/begin',
                complete_url = '/passkey/authenticate/complete';

            if (!use_header) {
                headers = {
                    'Content-Type': 'application/json'
                };

                begin_url = '/passkey/authenticate/beginLogin';
                complete_url = '/passkey/authenticate/completeLogin';
            }

            const beginResponse = await api.post(begin_url, null, { headers: headers }).catch(async (error) => {
                if (error.response.status === 401) {
                    showMessage('Your session has expired. Please login again.', 'error');
                    await store.dispatch('auth/logout');
                    await router.push({ path: '/auth/login' });
                }
                return Promise.reject(error);
            });

            const options = beginResponse.data;

            const processedAllowCredentials = options.allowCredentials.length > 0 ? options.allowCredentials.map(cred => ({
                ...cred,
                id: base64urlToUint8Array(cred.id)
            })) : [];

            const credential = await navigator.credentials.get({
                publicKey: {
                    ...options,
                    challenge: base64urlToUint8Array(options.challenge),
                    allowCredentials: processedAllowCredentials.length > 0 ? processedAllowCredentials : undefined, // Force empty array to prefer local sign-in
                    userVerification: 'required'
                }
            });

            const assertionResponse = {
                id: credential.id,
                rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
                response: {
                    authenticatorData: btoa(String.fromCharCode(...new Uint8Array(credential.response.authenticatorData))),
                    clientDataJSON: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON))),
                    signature: btoa(String.fromCharCode(...new Uint8Array(credential.response.signature))),
                    userHandle: credential.response.userHandle ? btoa(String.fromCharCode(...new Uint8Array(credential.response.userHandle))) : null
                },
                type: credential.type,
                session_id: options?.session_id
            };

            const completeResponse = await api.post(complete_url, assertionResponse, { headers: headers }).catch(async (error) => {
                return { data: { success: false, message: error.response?.data?.detail } };
            });

            if (completeResponse.data.success) {
                commit('loginSuccess', completeResponse.data.data);
                TokenService.setToken(completeResponse.data.data);
                return completeResponse;
            } else {
                return completeResponse;
            }
        },
        confirmation({ commit }, token) {
            return AuthService.confirmation(token);
        },
        new_user_invitation({ commit }, payload) {
            return AuthService.new_user_invitation({ payload });
        },
        invitation_token_check({ commit }, token) {
            return AuthService.invitation_token_check(token);
        },
        password_reset({ commit }, payload) {
            return AuthService.password_reset({ payload });
        },
        change_password({ commit }, payload) {
            return AuthService.change_password({ payload });
        },
        password_recovery_token_check({ commit }, token) {
            return AuthService.password_recovery_token_check(token);
        },
        password_recovery({ commit }, email) {
            return AuthService.password_recovery(email);
        },
        profile({ commit }, payload) {
            return AuthService.profile({ payload });
        }
    },
    mutations: {
        updateNeedsVerification(state, value) {
            state.status.needsVerification = value;
        },
        loginSuccess(state, token) {
            state.status.loggedIn = true;
            state.token = token;
        },
        updatedTokenSuccess(state, token) {
            state.token = token;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};
