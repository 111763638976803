<template>
    <div>
        <div class='overlay-setting' :class='{ show: isOpen }' @click='isOpen = !isOpen'></div>

        <div class='app-settings' :class='{ active: isOpen }'>
            <a href='javascript:;' id='settingbutton' class='setting' @click='isOpen = !isOpen'>
                <h5 class='mb-0'>
                    <font-awesome-icon icon='thumbs-up' />
                </h5>
            </a>
            <perfect-scrollbar class='sidbarchat p-3' tag='div'>
                <a class='btn-close' href='javascript:;' @click='isOpen = !isOpen'> </a>
                <div class='text-center'>
                </div>
                <h5 class='mb-0 text-center'>Feedback</h5>
                <div class='container mt-5'>
                    <h2 class='mb-4'>Feedback Form</h2>
                    <div>
                        <multiselect v-model='tags'
                                     :options='options'
                                     :searchable='true'
                                     :multiple='true'
                                     :close-on-select='true'
                                     label='name'
                                     track-by='name'
                                     :taggable='true'
                                     selected-label='' select-label='' deselect-label=''
                                     required></multiselect>
                    </div>
                    <div class='form-group mt-4'>
                        <label for='additional-comments'>Additional Comments:</label>
                        <textarea class='form-control' id='additional-comments' rows='5' v-model='comments'></textarea>
                    </div>

                    <button type='submit' class='btn btn-primary' @click='submit'>Submit Feedback</button>
                </div>

            </perfect-scrollbar>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    import { useStore } from 'vuex';
    import Textarea from '@/components/elements/textarea.vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Multiselect from '@suadelabs/vue3-multiselect';
    import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
    import { showMessage } from '@/composables/utils';
    import UserService from '@/services/user.service';

    const store = useStore();

    const tags = ref([]);
    const comments = ref('');
    const isOpen = ref(null);
    const options = [
        { id: 1, name: 'Helpful', is_positive: true },
        { id: 2, name: 'Good performance', is_positive: true },
        { id: 3, name: 'Easy to understand', is_positive: true },
        { id: 4, name: 'Steps are clear', is_positive: true },
        { id: 5, name: 'Slow', is_positive: false },
        { id: 6, name: 'Hard to understand', is_positive: false },
        { id: 7, name: 'Steps are not clear', is_positive: false },
        { id: 8, name: 'Incorrect Data', is_positive: false }
    ];

    const submit = async () => {
        // Must have comments or tags
        if (comments.value === '' && tags.value.length === 0) {
            showMessage('Please enter comments or tags', 'error');
            return;
        }

        // Get Current url
        const url = window.location.href;
        const tags_string = tags.value.map((tag) => tag.name).join(',');
        
        // Send feedback
        const data = {
            comments: comments.value,
            name: url,
            tags: tags_string
        };
        const response = await UserService.postData('/feedback', data);
        if (response.status === 200) {
            showMessage('Feedback submitted successfully', 'success');
            isOpen.value = false;
        } else {
            showMessage('Error submitting feedback', 'error');
        }
    };
</script>
