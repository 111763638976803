import axiosInstance from './api';
import TokenService from './token.service';
import router from '@/router';
import { showMessage } from '@/composables/utils';

export default async function setup() {
    axiosInstance.interceptors.request.use(
        async (config) => {
            // Check if Token expired exists fullToken.expires_at
            const fullToken = TokenService.getToken();
            if (fullToken && fullToken.expires_at) {
                const expiresAt = new Date(fullToken.expires_at);
                if (new Date() > expiresAt) {

                    let has_modal = false;
                    try {

                        // find and close any open modals
                        const modals = document.querySelectorAll('.modal');
                        modals.forEach(modal => {
                            const modalInstance = bootstrap.Modal.getInstance(modal);
                            if (modalInstance) {
                                modalInstance.hide();
                                has_modal = true;
                            }
                        });
                    } catch (e) {
                        console.log(e);
                    }

                    if (!has_modal) {
                        await router.push({ path: '/auth/force_logout' });
                    } else {
                        setTimeout(() => {
                            const modals = document.querySelectorAll('.modal');
                            modals.forEach(modal => {
                                const modalInstance = bootstrap.Modal.getInstance(modal);
                                if (modalInstance) {
                                    modalInstance.hide();
                                }
                            });

                            showMessage('Your Session has expired. Please login again', 'error');

                            setTimeout(() => {
                                router.push({ path: '/auth/force_logout' });
                            }, 500);

                        }, 500);
                    }
                }
            }

            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            if (err.response) {

                // Access Token was expired
                if (err.response.status === 401 || err.response.status === 503) {
                    // await router.push({ path: '/auth/force_logout' });
                }
            }

            return Promise.reject(err);
        }
    );
};
