<template>
    <div class='row p-0 m-0'>
        <div class='col-10'>
            <p class='m-0 p-0 p-header'>{{ item.name }}</p>
            
            <p class='m-0 p-0'>
                <a :href='item.url' target='_blank'>&nbsp;
                    <img :src='url' class='photo' :alt='item.name'/>
                </a>
            </p>

        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/photos/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { useStore } from 'vuex';

    /**
     * @typedef {Object} item
     * @property {string}  id
     * @property {string}  name
     * @property {string}  is_favorite
     * @property {string}  created_at
     * @property {string}  category_id
     */
    const props = defineProps({
        item: Object,
        color: String,
        is_archive: Boolean
    });

    const store = useStore();
    const api_url = ref(process.env.VUE_APP_ROOT_FAST_API);
    const access_token = store.state.auth.token.access_token;
    const url = ref(api_url.value + '/photos/' + props.item.token + '?access_token=' + access_token);

</script>

<style scoped>
    .chevron-right svg {
        top: unset !important;
    }
</style>
