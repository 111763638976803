<template>
    <div class='row p-0 m-1'>
        <div class='col-10'>
            <p class='m-0 p-0 p-header'>
                {{ item.name }}
            </p>

            <div class='m-0 p-0'>
                <div class='row'>
                    <div class='col' v-if='item.data_classification_color'>
                        <p class='status-search'>

                            <span class='text-muted'> {{ item.data_classification }} </span>

                            <font-awesome-icon icon='circle' class='mx-2'
                                               :color='item.data_classification_color'
                                               size='xs' />

                        </p>
                    </div>
                    <div class='col'>
                        <p class='m-0 p-0 text-muted'>
                            {{ (item.cost * item.qty).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }) }}
                        </p>
                    </div>
                </div>
            </div>
            
            <div class='mt-0'>
                <p v-if='item.tracking_number && item.tracking_url && item.tracking_courier'
                   :class="{'invisible':!(item.tracking_number && item.tracking_url)}">
                    <a :href='item.tracking_url.replace(/%s/,item.tracking_number)'
                       target='_blank'
                    >
                        <img :src="require('@/assets/images/couriers/'+item.tracking_courier+'.svg')"
                             :alt='item.category'
                             class='icon-ship' />
                        {{ item.tracking_number }}
                    </a>
                </p>
                <p v-else-if='item.tracking_courier'>
                    <img :src="require('@/assets/images/couriers/'+item.tracking_courier+'.svg')"
                         :alt='item.category'
                         class='icon-ship' />
                    {{ item.tracking_number }}
                </p>
                <p v-else-if='item.tracking_number'>
                    <span class='my_icon' v-if=' !item.tracking_url && !item.tracking_courier'>
                        <vue-feather type='truck' size='18' class='mx-4 float-start'></vue-feather>
                    </span>
                    <b>{{ item.tracking_number }}</b>
                </p>
                <p v-else class='tracking-gap'>
                </p>
            </div>
        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/expenses/" + item.expense_id + "/items/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string}  id
     * @property {string}  name
     * @property {string}  version
     * @property {string}  seats
     * @property {string}  cost
     * @property {string}  category
     * @property {string}  type
     */
    const props = defineProps({
        item: Object,
        is_archive: Boolean
    });

    const item = ref(props.item);

</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .my_icon {
        top: -8px;
        position: relative !important;
    }

    .my_icon b {
        top: 0;
    }


    .p-header {
        font-size: 15px;
    }
</style>
