<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1 text-center">
            <p class="">&copy; {{year}}  <a target="_blank" href="https://tinyelectrons.com">Tiny Electrons LLC</a>, All rights reserved. Version {{version}}</p>
        </div>
    </div>
    <!-- END FOOTER -->
</template>
<script setup>
    const version=  process.env.VUE_APP_VERSION
    const year = new Date().getFullYear();
</script>
