import { createStore } from 'vuex';
import i18n from '../i18n';

import { auth } from './auth.module';

export default new createStore({
    state: {
        layout: 'app',
        redirect_path: '/',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'dark',
        locale: null,
        triggerRelationshipUpdate: false,
        triggerDeleteClick: false,
        triggerSaveClick: false,
        triggerPlusButtonClick: false,
        triggerEscapeKey: false,
        currentSortBy: 'name',
        currentSortByTitle: 'Name',
        currentSortDirection: 'ascending',
        lookups: { 'colors': {}, 'icons': {}, 'images': {}, 'default_values': {} },
        cached_object: null,
        menu_style: 'vertical',
        layout_style: 'full',
        searchParams: {},
        tempToken: {},
        countryList: [
            { code: 'en', name: 'English' },
            { code: 'es', name: 'Spanish' }
        ]
    },
    mutations: {
        setLookups(state, params) {
            // TODO: update the values if the key already exists

            for (const key_or_id in params.value) {
                if (key_or_id === 'colors') {
                    for (const key in params.value.colors) {
                        if (params.value.colors.hasOwnProperty(key)) {
                            const element = params.value.colors[key];
                            if (!state.lookups.colors[key]) {
                                state.lookups.colors[key] = element;

                                // set default_values for elements
                                const has_default = element.find((x) => x.is_default === true);
                                if (has_default) {
                                    if (!state.lookups.default_values[key]) {
                                        state.lookups.default_values[key] = has_default;
                                    }
                                }

                            }
                        }
                    }
                } else if (key_or_id === 'icons') {
                    for (const key in params.value.icons) {
                        if (params.value.icons.hasOwnProperty(key)) {
                            const element = params.value.icons[key];
                            if (!state.lookups.icons[key]) {
                                state.lookups.icons[key] = element;

                                // set default_values for elements
                                const has_default = element.find((x) => x.is_default === true);
                                if (has_default) {
                                    if (!state.lookups.default_values[key]) {
                                        state.lookups.default_values[key] = has_default;
                                    }
                                }
                            }
                        }
                    }
                } else if (key_or_id === 'images') {
                    for (const key in params.value.images) {
                        if (params.value.images.hasOwnProperty(key)) {
                            const element = params.value.images[key];
                            if (!state.lookups.images[key]) {
                                state.lookups.images[key] = element;

                                // set default_values for elements
                                const has_default = element.find((x) => x.is_default === true);
                                if (has_default) {
                                    if (!state.lookups.default_values[key]) {
                                        state.lookups.default_values[key] = has_default;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (const key in params.value) {
                        if (params.value.hasOwnProperty(key)) {
                            const element = params.value[key];
                            if (!state.lookups[key]) {
                                state.lookups[key] = element;

                                // Check if element is an array before using find
                                if (Array.isArray(element)) {
                                    const has_default = element.find((x) => x.is_default === true);
                                    if (has_default) {
                                        if (!state.lookups.default_values[key]) {
                                            state.lookups.default_values[key] = has_default;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        appendLookup(state, params) {
            const section = params.section;
            if (section === 'colors' || section === 'icons' || section === 'images') {
                if (!state.lookups[section][params.key]) {
                    state.lookups[section][params.key] = [];
                }
                state.lookups[section][params.key].push(params.value);
            } else {
                if (!state.lookups[params.key]) {
                    state.lookups[params.key] = [];
                }
                state.lookups[params.key].push(params.value);
            }
        },
        setSearchParams(state, params) {
            state.searchParams = params;
        },
        setTempToken(state, token) {
            state.tempToken = token;
        },
        setLayout(state, payload) {
            state.layout = payload;
        },
        setRedirectPath(state, payload) {
            state.redirect_path = payload;
        },
        setCachedObject(state, payload) {
            state.cached_object = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },
        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'dark';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value === 'light') {
                state.is_dark_mode = false;
            } else if (value === 'dark') {
                state.is_dark_mode = true;
            } else if (value === 'system') {
                state.is_dark_mode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                // Check if has class to remove
                if (document.querySelector('body').classList.contains('dark')) {
                    document.querySelector('body').classList.remove('dark');
                }
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },

        setTrigger(state, value) {
            state.triggerPlusButtonClick = value;
        },
        setEscapeKey(state, value) {
            state.triggerEscapeKey = value;
        },
        setSaveClick(state, value) {
            state.triggerSaveClick = value;
        },
        setDeleteClick(state, value) {
            state.triggerDeleteClick = value;
        },
        setSortBy(state, value) {
            state.currentSortBy = value;
        },
        setSortByTitle(state, value) {
            state.currentSortByTitle = value;
        },
        setSortDirection(state, value) {
            state.currentSortDirection = value;
        },
        setTriggerRelationshipUpdate(state, value) {
            state.triggerRelationshipUpdate = value;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        redirectPath(state) {
            return state.redirect_path;
        },
        getLookups: (state) => (field) => {
            return state.lookups[field];
        }
    },
    actions: {
        updateSearchParams({ commit }, params) {
            commit('setSearchParams', params);
        },
        updateTempToken({ commit }, token) {
            commit('setTempToken', token);
        },
        updateLookups({ commit }, field, lookups) {
            commit('setLookups', field, lookups);
        },
        triggerPlusButton({ commit }) {
            commit('setTrigger', true);

            // Reset after the action is acknowledged
            setTimeout(() => {
                commit('setTrigger', false);
            }, 100);
        },
        triggerEscapeKey({ commit }) {
            commit('setEscapeKey', true);

            // Reset after the action is acknowledged
            setTimeout(() => {
                commit('setEscapeKey', false);
            }, 100);
        },
        triggerSaveClick({ commit }) {
            commit('setSaveClick', true);

            // Reset after the action is acknowledged
            setTimeout(() => {
                commit('setSaveClick', false);
            }, 100);
        },
        triggerDeleteClick({ commit }) {
            commit('setDeleteClick', true);

            // Reset after the action is acknowledged
            setTimeout(() => {
                commit('setDeleteClick', false);
            }, 100);
        },
        updateSortBy({ commit }, value) {
            commit('setSortBy', value);
        },
        updateSortByTitle({ commit }, value) {
            commit('setSortByTitle', value);
        },
        updateSortDirection({ commit }, value) {
            commit('setSortDirection', value);
        },
        updateTriggerRelationshipUpdate({ commit }, value) {
            commit('setTriggerRelationshipUpdate', value);
        }
    },
    modules: {
        auth
    }
});
