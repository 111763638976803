<template>
    <div class='row p-0 m-0'>
        <div class='col-10'>
            <p class='m-0 p-0 p-header'>{{ item.name }}</p>
            

            <p class='m-0 p-0'>
                <a :href='item.url' target='_blank'>
                    <font-awesome-icon icon='globe' class='my_icon' />
                    &nbsp;
                    {{ shortString(item.url) }}
                </a>
            </p>

        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/bookmarks/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string}  id
     * @property {string}  name
     * @property {string}  is_favorite
     * @property {string}  created_at
     * @property {string}  category_id
     */
    const props = defineProps({
        item: Object,
        color: String,
        is_archive: Boolean
    });

    const shortString = function(u) {
        const end = u.slice(u.length - 15);
        const start = u.replace('http://', '').replace('https://', '').substr(0, 22);
        return start + '...' + end;
    };
</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .my_icon {
        top: 0 !important;
        position: relative !important;
    }
</style>
