<template>
    <div class='row p-0 m-1'>
        <div class='col-10'>
            <h3 class='m-0 p-0 p-header'>
                {{ item.name }}
            </h3>

            <p class='m-0 p-0 '>
                {{ item.version }}
            </p>

            <div class='m-0 p-0'>
                <div class='row'>
                    <div class='col'>
                        <p class='status-search'>
                            <span class='text-muted'> {{ item.frequency }} </span>
                        </p>
                    </div>
                    <div class='col'>
                        <p class='m-0 p-0 text-muted'>
                            {{ item.seats ? convertToCurrency(item.cost * item.seats) : convertToCurrency(item.cost) }}
                        </p>
                    </div>
                </div>
            </div>

            <div class='m-0 p-0'>
                <div class='row'>
                    <div class='col'>
                        <p>
                            {{item.seat_count === null ? 0: item.seat_count}} / {{ item.seats}} seats
                        </p>
                    </div>
                </div>
            </div>
            

            <p class='m-0 p-0'>
                <template v-if='item.sector_icon ==="windows" '>
                    <font-awesome-icon :icon="[ 'fab', 'windows' ]" />
                </template>
                <template v-else>
                    <font-awesome-icon
                        :icon='item.sector_icon'
                        v-if='item.sector_icon' />
                </template>

                <font-awesome-icon
                    :icon='item.type_icon'
                    class='mx-2'
                    v-if='item.type_icon' />

            </p>
        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/licenses/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string} id
     * @property {string} name
     * @property {string} version
     * @property {string} seats
     * @property {string} cost
     * @property {string} category
     * @property {string} type
     * @property {string} frequency_id
     */
    const props = defineProps({
        item: Object,
        color: String,
        is_archive: Boolean
    });

    const convertToCurrency = (value) => {

        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    };
</script>

<style scoped>

    .my_icon {
        top: 0 !important;
        position: relative !important;
    }

    .chevron-right svg {
        top: unset !important;
    }

    .p-header {
        font-size: 15px;
    }
</style>
