<template>
    <div class='row p-0 m-0'>
        <div class='col-10'>
            <p class='m-0 p-0'>
                <a :href='item.url' target='_blank'>
                    <template v-if='icon'>
                        <font-awesome-icon size='2x'
                                           :icon='icon' class='my_icon' />
                        {{ item.name }}
                    </template>
                    <template v-else>
                        <font-awesome-icon icon='globe' class='my_icon' />
                        &nbsp; {{ shortString(item.url) }}
                    </template>
                </a>
            </p>

        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/keys/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string} id
     * @property {string} name
     * @property {string} ssid
     * @property {string} key_type_id
     * @property {string} wireless_encryption_type_id
     * @property {string} database_type_id
     * @property {string} sid
     * @property {string} email_type_id
     * @property {string} email_incoming_server
     * @property {string} email_outgoing_server
     * @property {string} email_incoming_port
     * @property {string} email_outgoing_port
     * @property {string} email_incoming_security_type_id
     * @property {string} email_outgoing_security_type_id
     * @property {string} vpn_type_id
     * @property {string} account_name
     * @property {string} remote_id
     * @property {string} local_id
     * @property {string} bitlocker_key_id
     * @property {string} public_key
     * @property {string} is_favorite
     * @property {string} created_at
     * @property {string} category_id
     * @property {string} chain
     * @property {string} certificate
     * @property {string} secure_notes
     * @property {string} passphrase
     * @property {string} secret
     * @property {string} username
     * @property {string} password
     * @property {string} csr
     * @property {string} bitlocker_restore_key
     * @property {string} private_key
     * @property {string} encryption_key
     * @property {string} pin
     * @property {string} category_id
     */
    const props = defineProps({
        item: Object,
        icon: String,
        is_archive: Boolean
    });

    const shortString = function(u) {

        if (u) {
            const end = u.slice(u.length - 15);
            const start = u.replace('http://', '').replace('https://', '').substr(0, 15);
            return start + '...' + end;
        } else {
            return u;
        }
    };
</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .my_icon {
        top: 0 !important;
        position: relative !important;
    }
</style>
