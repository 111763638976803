<template>
  <tr :class="props.theme.groupTr"  @click="props.toggleGroup.call(this, props.groupValue, $event)">
    <td :colspan="props.colspan">
      <span v-if="props.canToggleGroup">
        <font-awesome-icon icon="folder-closed" color="#2793f9" size="2x"
                           v-if="props.groupToggleIcon(props.groupValue) === 'glyphicon glyphicon-chevron-down'"/>
        <font-awesome-icon icon="folder-open" color="#2793f9" size="2x" v-else/>
        {{ props.groupValue !== "null" ? props.groupValue : "None" }}
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "MyGroupRow",
  props: ['props'],
}
</script>
