<template>
    <div class='col-md-12'>
        <div class='form-group mb-4'>
            <label :for='id'>{{ label }}</label>
            <textarea type='text'
                      v-model='form.input_value'
                      class='form-control'
                      :id='id'
                      :class="is_submit_form && is_required ? (form.input_value !== null  ? 'is-valid' : 'is-invalid') : ''"
            />
            <div class='invalid-feedback'>Please input {{ label }}</div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref, computed, watch, defineEmits } from 'vue';

    const {
        is_small,
        label,
        is_required,
        is_submit_form,
        modelValue: inputFromParent
    } = defineProps(['is_small', 'label', 'is_required', 'is_submit_form', 'modelValue']);

    const form = ref({
        input_value: inputFromParent || null
    });

    const id = computed(() => {
        return label.toLowerCase().replace(' ', '_');
    });

    const emit = defineEmits(['update:modelValue']);

    watch(() => form.value.input_value, (newValue) => {
        emit('update:modelValue', newValue);
    });
</script>
