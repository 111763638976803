<template>
    <div class='row p-0 m-0'>
        <div class='col-10'>
            <h3 class='m-0 p-0 p-header'>
                {{ item.name }}
            </h3>

            <p class='m-0 p-0 '>
                {{ item.version }}
            </p>

            <div class='m-0 p-0'>
                <div class='row'>
                    <div class='col'>
                        <p class='status-search'>
                            <span class='text-muted'> {{ item.frequency }} </span>
                        </p>
                    </div>
                    <div class='col'>
                        <p class='m-0 p-0 text-muted'>
                            {{ item.seats ? convertToCurrency(item.cost * item.seats) : convertToCurrency(item.cost) }}
                        </p>
                    </div>
                </div>
            </div>
            
            <div class='m-0 p-0'>
                <div class='row'>
                    <div class='col'>
                        <p>
                        {{item.seat_count === null ? 0: item.seat_count}} / {{ item.seats}} seats
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/subscriptions/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string}  id
     * @property {string}  name
     * @property {string}  account
     * @property {string}  sub_account
     */
    const props = defineProps({
        item: Object,
        is_archive: Boolean
    });

    const convertToCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        
    };
</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .p-header {
        font-size: 15px;
    }
</style>
